import Vue from 'vue'
import App from '@/App.vue'
import router from "@/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import store from '@/store'
import { utils } from '@/utils/utils.js';
import '@/assets/font-awesome/css/font-awesome.css'

// 全局注册工具方法对象
Vue.prototype.$utils = utils;

Vue.prototype.$store = store
window.axios = axios
Vue.prototype.axios = axios
Vue.use(ElementUI);
Vue.config.productionTip = false
//配置域名
import config from '../config/app.js'
Vue.prototype.$baseURL = config.HTTP_REQUEST_URL
Vue.prototype.$siteName = config.SITE_NAME
Vue.prototype.$siteUrl = config.SITE_URL
Vue.prototype.$baseURL = config.HTTP_REQUEST_URL
Vue.prototype.$uploadUrl = config.HTTP_REQUEST_URL + '/admin/upload/upimage'
Vue.prototype.$uploadChunkUrl = config.HTTP_REQUEST_URL + '/admin/upload/upchunk'
Vue.prototype.$uploadFileUrl = config.HTTP_REQUEST_URL + '/admin/upload/upfile'
axios.defaults.baseURL = Vue.prototype.$baseURL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//axios请求前拦截
axios.interceptors.request.use(function (config) {
    //带上vuex里存储的token
    if (store.state.user.userInfo && store.state.user.userInfo.token) {
        console.log(store.state.user.userInfo.token)
        //每次post请求时，需要手动加上token到对象里
        if (config.method === 'post') {
            config.data.token = store.state.user.userInfo.token;
        }
    }
    return config;
});
//axios请求后拦截
axios.interceptors.response.use(function (response) {
    if(response.data.status <= -100){
        ElementUI.Message({
            message: '登录失效，请重新登录',
            type: 'error',
            duration: 700,
        });
        setTimeout(() => {
            //清除token
            store.commit('user/logout');
            router.push({path: '/login'});
        })
        return Promise.reject(response.data);
    }
    if (response.data.status === 0) {
        ElementUI.Message({
            message: response.data.msg,
            type: 'error',
            duration: 700,
        });
        return Promise.reject(response.data);
    }
    return response.data;
});
// element消息拦截，多个请求同时返回时，只弹出一个消息提示

import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
    if (messageInstance) {
        messageInstance.close();
    }
    options.showClose = true;
    messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
    resetMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            };
        }
        options.type = type;
        return resetMessage(options);
    };
});
Vue.prototype.$message = resetMessage;
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
