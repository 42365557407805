const baseHost = window.location.protocol + "//" + window.location.host;
module.exports = {
	// HTTP_REQUEST_URL: baseHost,
	HTTP_REQUEST_URL: `https://k205.cc`,
	SITE_NAME:`七个瓜`,
	SITE_URL:`https://f120.cc`,
	// HTTP_REQUEST_URL: `http://localhost:24713`,
	// HTTP_REQUEST_URL: `https://dangjian-zhongzi.peopledata.com.cn`,
	RSA_PUBLIC_KEY: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCpsBiju+Q5vqKBa775lPotix/J2Uke69oQNTH2hN4MYcgyKfAZFUXMX47g3jMVz7wJckH8qbHJ9VeDHZgzIP1UmgA4UgDXDPrCpt2yQESSarGriQ9RUpW6bRTDNKja98IvLqqwjXwcACT4tS4nR/bgLK5ZnUMt5KuWyNdV4lA6sQIDAQAB'
}
