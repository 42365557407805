<template>
	<div class="footer">
		<div class="top">
			<a class="item">
				<i class="fa fa-home" aria-hidden="true"></i>
				吃瓜首页
			</a>
			<a class="item">
				<i class="fa fa-android" aria-hidden="true"></i>
				吃瓜APP
			</a>
			<a class="item">
				<i class="fa fa-gift" aria-hidden="true"></i>
				福利应用
			</a>
			<a class="item" href="https://qm.qq.com/q/itjiYopgd2" target="_blank">
				<i class="fa fa-qq" aria-hidden="true"></i>
				吃瓜QQ群
			</a>
		</div>
		<div class="text">
			<h3>{{$siteName}} - 全网更新最快最全的吃瓜网！</h3>
			<p>
				{{$siteName}}一直致力于免费为广大瓜友提供最优质的吃瓜内容，欢迎分享给你的小伙伴们～
			</p>
			<p>
				{{$siteName}}网永久地址 (打不开请翻墙或访问最新吃瓜地址) <br>
				<a :href="$siteUrl">{{$siteUrl}}</a>
			</p>
		</div>
		<div class="footer-link-box">
			<a href="https://t.me/Ben5561" class="footer-link">商务合作</a>
			<a href="https://t.me/Ben5561" class="footer-link">往期内容</a>
			<a href="https://t.me/Ben5561" class="footer-link">常见问题</a>
			<a href="https://t.me/Ben5561" class="footer-link">投稿方式</a>
			<a href="https://t.me/Ben5561" class="footer-link">回家的路</a>
			<a href="https://t.me/Ben5561" class="footer-link">加入我们</a>
		</div>
		<div class="bottom">
			<a href="https://t.me/Ben5561">
				<i class="fa fa-telegram"></i>
			</a>
			<a href="">
				<i class="fa fa-twitter"></i>
			</a>
			<a href="">
				<i class="fa fa-github"></i>
			</a>
			<a href="">
				<i class="fa fa-meetup"></i>
			</a>
		</div>
		<div class="footer-footer">
			Copyright © 2024 <a :href="$siteUrl">{{$siteName}} 全网更新最快最全的吃瓜网！ </a>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'cgHeader',
		data() {
			return {
				keyword: null,
				isMenuOpen: false, // 用于控制菜单的显示
			}
		},
		created() {
		},
		methods: {
		}
	}
</script>

<style scoped lang="scss">
	.footer {
		color: #eaeaea;
		width: 10rem;
		height: .6rem;
		background-color: #2c2a2a;
		margin: 0 auto;
		border-top: 1px solid #444;
		padding-top: .4rem;
		margin-top: .4rem;
		
		.footer-footer{
			text-align: center;
			font-size:.12rem;
			padding-bottom: .2rem;
			
			a{
				font-size:.12rem;
			}
		}
		
		a{
			text-decoration: none;
			color: #fff;
			font-size:.16rem;
		}
		
		.top{
			display: flex;
			align-items: center;
			margin: 0 auto;
			justify-content: space-between;
			padding: 0 1rem;
			margin-bottom: .4rem;
			
			.item{
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				
				i{
					font-size: .3rem;
				}
			}
		}
		
		.text{
			
			margin-bottom: .2rem;
			h3{
				font-size: .18rem;
				margin-bottom: .1rem;
			}
			p{
				font-size: .14rem;
				line-height: .24rem;
			}
			a{
				color: aquamarine;
			}
		}
		
		.footer-link-box{
			display: flex;
			flex-direction: column;
			
			.footer-link{
				cursor: pointer;
				text-align: left;
				line-height: .5rem;
				border-top: 1px solid #444;
				
				&:last-child{
					border-bottom: 1px solid #444;
					margin-bottom: .4rem;
				}
			}
		}
		
		.bottom{
			display: flex;
			align-items: center;
			margin: 0 auto;
			justify-content: space-between;
			padding: 0 30%;
			margin-bottom: .2rem;
			
			a{
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				
				i{
					font-size: .3rem;
				}
			}
		}
		
		@media screen and (max-width: 1199px) {
			width: 15.88rem;
			
			.footer-footer{
				font-size:.24rem;
				
				a{
					font-size:.24rem;
				}
			}
			
			a{
				font-size:.24rem;
			}
			
			.top{
				padding: 0 1rem;
				margin-bottom: .4rem;
				
				.item{
					i{
						font-size: .48rem;
					}
				}
			}
			
			.text{
				margin-bottom: .2rem;
				h3{
					font-size: .28rem;
					margin-bottom: .1rem;
				}
				p{
					font-size: .24rem;
					line-height: .32rem;
				}
			}
			
			.footer-link-box{
				.footer-link{
					font-size: .24rem;
					line-height: 1rem;
					border-top: 1px solid #444;
					
					&:last-child{
						border-bottom: 1px solid #444;
						margin-bottom: .8rem;
					}
				}
			}
			
			.bottom{
				margin-bottom: .8rem;
				
				a{
					i{
						font-size: .4rem;
					}
				}
			}
		}
		
		@media screen and (max-width: 799px) {
			width: 90%;
			margin: 0 auto;
			
			.footer-footer{
				font-size:.48rem;
				
				a{
					font-size:.48rem;
				}
			}
			
			a{
				font-size:.48rem;
			}
			
			.top{
				margin-top: 1rem;
				padding: 0 2rem;
				margin-bottom: .8rem;
				
				.item{
					i{
						font-size: .92rem;
					}
				}
			}
			
			.text{
				margin-bottom: .4rem;
				h3{
					font-size: .56rem;
					margin-bottom: .2rem;
				}
				p{
					font-size: .48rem;
					line-height: .64rem;
				}
			}
			
			.footer-link-box{
				.footer-link{
					font-size: .48rem;
					line-height: 2rem;
					border-top: 1px solid #444;
					
					&:last-child{
						border-bottom: 1px solid #444;
						margin-bottom: 1.6rem;
					}
				}
			}
			
			.bottom{
				margin-bottom: 1.6rem;
				
				a{
					i{
						font-size: .8rem;
					}
				}
			}
		}
	}
</style>