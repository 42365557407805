<template>
	<div class="header">
		<div class="mobile-header">
			<div class="top-box">
				<div class="menu-btn" @click="toggleMenu">
					<i class="fa fa-bars" aria-hidden="true" v-if="!isMenuOpen"></i>
					<i class="fa fa-times" aria-hidden="true" v-if="isMenuOpen"></i>
				</div>
				<div class="logo">
					{{$siteName}}网
				</div>
				<a class="tg-btn" href="https://t.me/Ben5561" target="_blank">
					<i class="fa fa-telegram" aria-hidden="true"></i>
				</a>
			</div>
			<div class="menu-box" v-show="isMenuOpen">
				<div class="search">
					<el-input placeholder="搜索视频" v-model="keyword" class="input-with-select">
						<el-button slot="append" icon="el-icon-search" @click="search"></el-button>
					</el-input>
				</div>
				<div class="nav-list">
					<div v-for="(item,index) in list" :key="index" class="item" @click="selectType(item.type_id)">
						{{item.type_name}}
					</div>
				</div>
				<div class="contact">
					<a href="">
						<i class="fa fa-github"></i>
					</a>
					<a href="https://t.me/Ben5561" target="_blank">
						<i class="fa fa-telegram"></i>
					</a>
					<a  href="https://qm.qq.com/q/itjiYopgd2" target="_blank">
						<i class="fa fa-qq"></i>
					</a>
					<a href="">
						<i class="fa fa-twitter"></i>
					</a>
					<a href="">
						<i class="fa fa-bookmark"></i>
					</a>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="logo" @click="toIndex">
				{{$siteName}}
			</div>
			<div class="nav-list">
				<div v-for="(item,index) in list" :key="index" class="item" @click="selectType(item.type_id)">
					{{item.type_name}}
				</div>
			</div>
			<div class="search">
				<el-input placeholder="搜索视频" v-model="keyword" class="input-with-select">
					<el-button slot="append" icon="el-icon-search" @click="search"></el-button>
				</el-input>
			</div>
			<div class="contact">
				<a href="">
					<i class="fa fa-github"></i>
				</a>
				<a href="https://t.me/Ben5561" target="_blank">
					<i class="fa fa-telegram"></i>
				</a>
				<a  href="https://qm.qq.com/q/itjiYopgd2" target="_blank">
					<i class="fa fa-qq"></i>
				</a>
				<a href="">
					<i class="fa fa-twitter"></i>
				</a>
				<a href="">
					<i class="fa fa-bookmark"></i>
				</a>
			</div>
			<!-- <div class="login">
        <div class="item">
          登录
        </div>
        <div class="item">
          注册
        </div>
      </div> -->
		</div>
	</div>
</template>
<script>
	import {
		getData
	} from '@/api/index';

	export default {
		name: 'cgHeader',
		data() {
			return {
				list: [],
				keyword: null,
				isMenuOpen: false, // 用于控制菜单的显示
			}
		},
		created() {
			console.log(this.$route.path)
			this.getList()
		},
		methods: {
			toggleMenu() {
				this.isMenuOpen = !this.isMenuOpen;
			},
			toIndex() {
				this.$router.push({
					path: '/'
				})
			},
			getList() {
				return new Promise((resolve, reject) => {
					getData({
						ac: 'list'
					}).then(res => {
						this.list = res.class
						resolve()
					}).catch(err => {
						reject()
					})
				})
			},
			selectType(tid) {
				this.toggleMenu()
				this.$emit('select-type', tid);
			},
			search() {
				this.toggleMenu()
				this.$emit('search', this.keyword);
			}
		}
	}
</script>

<style scoped lang="scss">
	.header {
		width: 100%;
		min-height: .8rem;
		background-color: #fff;
		text-align: center;
		//line-height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 .2rem;
		box-sizing: border-box;
		background-color: rgba(52, 50, 50, 0.7);
		position: fixed;
		z-index: 99;

		.mobile-header {
			display: none;
		}

		.content {
			display: flex;
			margin: 0 .2rem;
			display: flex;
			align-items: center;
			width: 100%;
			color: #fff;

			.logo {
				font-size: .2rem;
				cursor: pointer;
				font-weight: bold;
				width: 1.4rem;
				flex-shrink: 0;
			}

			.nav-list {
				margin-left: .4rem;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				// max-width: 800px;

				.item {
					margin-right: .2rem;
					cursor: pointer;
					font-size: .16rem;
				}
			}

			.search {
				// flex: 1;
				width: 2rem;
				margin-left: auto;
				height: .4rem;
				box-sizing: border-box;

				input {
					width: 1.5rem;
					height: .4rem;
					border: 0;
					border-radius: .15rem;
					padding: 0 .2rem;
					box-sizing: border-box;
				}
			}

			.contact {
				display: flex;

				a {
					background-color: #232121;
					border-radius: 1000px;
					line-height: .4rem;
					color: #fff;
					font-size: .16rem;
					display: block;
					width: .4rem;
					height: .4rem;
					margin-left: .1rem;

					&:hover {
						background-color: #fff;
						color: #232121;
					}
				}
			}
		}

		img {
			height: .3rem;
			cursor: pointer;
		}

		.right {
			display: flex;
			align-items: center;

			span {
				margin-right: .1rem;
				cursor: pointer;

				&:hover {
					color: #d81d06;
				}
			}
		}

		.login {
			display: flex;
			align-items: center;
			margin-left: auto;

			.item {
				margin-left: .2rem;
				cursor: pointer;
			}
		}

		@media screen and (max-width: 1199px) {
			min-height: 1rem;
			padding: 0 .2rem;

			.mobile-header {
				display: none;
			}

			.content {
				display: flex;
				margin: 0 .2rem;

				.logo {
					font-size: .32rem;
					width: 1.4rem;
				}

				.nav-list {
					margin-left: .4rem;

					.item {
						margin-right: .2rem;
						font-size: .24rem;
					}
				}

				.search {
					width: 2.8rem;
					height: .56rem;

					input {
						width: 2rem;
						height: .56rem;
						border-radius: .15rem;
						padding: 0 .32rem;
					}
				}

				.contact {
					display: flex;

					a {
						line-height: .56rem;
						font-size: .24rem;
						width: .56rem;
						height: .56rem;
						margin-left: .1rem;

						&:hover {
							background-color: #fff;
							color: #232121;
						}
					}
				}
			}

			img {
				height: .3rem;
			}

			.right {
				display: flex;
				align-items: center;

				span {
					margin-right: .1rem;
					cursor: pointer;

					&:hover {
						color: #d81d06;
					}
				}
			}

			.login {
				.item {
					margin-left: .4rem;
				}
			}
		}

		@media screen and (max-width: 799px) {
			padding: 0;

			.content {
				display: none;
			}

			.mobile-header {
				box-sizing: border-box;
				padding: .4rem 5%;
				display: flex;
				flex-direction: column;
				width: 100%;
				// overflow: scroll;
				background-color: #2c2a2a;

				.top-box {
					display: flex;
					align-items: center;
					font-size: 1rem;
					color: #fff;
					justify-content: space-between;
					width: 100%;
					box-sizing: border-box;
					font-weight: bold;

					a {
						color: #fff;
						text-decoration: none;
					}
				}

				.menu-box {
					// display: none;
					// height: 100vh;
					flex: 1;
					background-color: #2c2a2a;

					.nav-list {
						.item {
							color: #fff;
							font-size: .7rem;
							border-top: 1px solid #515151;
							line-height: 1.2rem;
							margin-top: .4rem;
							padding-top: 0.4rem;
						}
					}

					.contact {
						display: flex;
						justify-content: space-between;
						padding: 5%;
						width: 100%;
						box-sizing: border-box;
						background-color: #2b2b2b;

						a {
							color: #fff;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
</style>