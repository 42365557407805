import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../../config/app.js'
var siteName = config.SITE_NAME
var siteUrl = config.SITE_URL
var baseURL = config.HTTP_REQUEST_URL

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index/index.vue'),
        meta: {
            title: siteName + '网'
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/index/detail.vue'),
        meta: {
            title: siteName + ' - 详情'
        }
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: '/user',
    routes
})

export default router
